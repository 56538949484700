import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Container, Divider, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const USEFUL_LINKS = [
  { name: 'Home', link: '/', showDivider: true },
  { name: 'About Us', link: '/about-us', showDivider: true },
  { name: 'Products', link: '/products', showDivider: true },
  { name: 'Quality', link: '/quality', showDivider: true },
  { name: 'Contact Us', link: '/contact-us', showDivider: false },
];

const PRODUCTS_LINKS = [
  // { name: 'Terminal Bars', link: '/products/terminal-bars', showDivider: true },
  { name: 'Brass Fittings', link: '/products/brass-fittings', showDivider: true },
  { name: 'Brass Inserts', link: '/products/brass-inserts', showDivider: true },
  { name: 'Brass Terminals', link: '/products/brass-terminals', showDivider: true },
  { name: 'Brass Connectors', link: '/products/brass-connectors', showDivider: true },
  { name: 'Precision Eng. Parts', link: '/products/precision-eng-parts', showDivider: false },
];

function Footer() {
  return (
    <div style={{ backgroundColor: '#141414', color: 'whitesmoke' }}>
      <br />

      <Container>
        <Grid container textAlign='left' spacing={4}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant='h5'>Contact Us</Typography>

            <Divider color='white' />
            <br />

            <table>
              <tbody>
                <tr>
                  <td>
                    <LocationOnIcon style={{ color: '#cbcbcb', width: 15 }} />
                  </td>

                  <td>
                    <Typography variant='body1' style={{ color: '#cbcbcb' }}>
                      Shop No: B-16, Hirji Mistry Ancillary Bhavan,
                      <br />
                      Opp. Shed No: B-46, Udhyog Nagar,
                      <br />
                      Jamnagar - 361004, Gujarat (INDIA)
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td>
                    <PhoneIcon style={{ color: '#cbcbcb', width: 15 }} />
                  </td>

                  <td>
                    <Typography variant='body1'>
                      <a href='tel:+919377752444' style={{ textDecoration: 'none', color: '#cbcbcb' }}>
                        +91 93777 52444
                      </a>
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td>
                    <MailOutlineIcon style={{ color: '#cbcbcb', width: 15 }} />
                  </td>

                  <td>
                    <Typography variant='body1'>
                      <a href='mailto:metals@systechmetals.com' style={{ textDecoration: 'none', color: '#cbcbcb' }}>
                        metals@systechmetals.com
                      </a>
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant='h5'>Useful links</Typography>

            <Divider color='white' />

            <br />

            {USEFUL_LINKS.map((link, index) => {
              return (
                <div key={index}>
                  <Link to={link.link} style={{ textDecoration: 'none', color: '#cbcbcb' }}>
                    <Typography variant='body1' style={{ padding: 5 }}>
                      {link.name}
                    </Typography>
                  </Link>

                  {link.showDivider && <Divider color='#cbcbcb' />}
                </div>
              );
            })}
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant='h5'>Products</Typography>

            <Divider color='white' />

            <br />

            {PRODUCTS_LINKS.map((link, index) => {
              return (
                <div key={index}>
                  <Link to={link.link} style={{ textDecoration: 'none', color: '#cbcbcb' }}>
                    <Typography variant='body1' style={{ padding: 5 }}>
                      {link.name}
                    </Typography>
                  </Link>

                  {link.showDivider && <Divider color='#cbcbcb' />}
                </div>
              );
            })}
          </Grid>

          <Grid item xs={12} textAlign='center'>
            <Typography variant='body1' style={{}}>
              © COPYRIGHTS {new Date().getFullYear()} Systech Metals. ALL RIGHTS RESERVED.
              <br />v{process.env.REACT_APP_VERSION}
            </Typography>
          </Grid>
        </Grid>

        <br />
      </Container>
    </div>
  );
}

export default Footer;
