import { Typography } from '@mui/material';
import { colors } from 'helpers/colors';
import { NavLink } from 'react-router-dom';

function RangeOfProducts() {
  return (
    <div>
      <Typography variant='h5' color={colors.RED}>
        Range of Products
      </Typography>

      <ul>
        {[
          // { name: 'Terminal Bars', linkTo: '/products/terminal-bars' },
          { name: 'Brass Fittings', linkTo: '/products/brass-fittings' },
          { name: 'Brass Inserts', linkTo: '/products/brass-inserts' },
          { name: 'Brass Terminals', linkTo: '/products/brass-terminals' },
          { name: 'Brass Connectors', linkTo: '/products/brass-connectors' },
          { name: 'Precision Eng. Parts', linkTo: '/products/precision-eng-parts' },
          { name: 'Sheet Metal Components', linkTo: '/products/sheet-metal-components' },
          { name: 'Aluminum Turned Parts', linkTo: '/products/aluminum-turned-parts' },
          { name: 'Copper/Bronze/Ceramic Assembly Components', linkTo: '/products/assembly-components' },
          { name: 'M.S. & S.S. Turned Parts', linkTo: '/products/ms-ss-turned-parts' },
          { name: 'Cable Glands', linkTo: '/products/cable-glands' },
          { name: 'Industrial Nuts', linkTo: '/products/industrial-nuts' },
        ].map((product, index) => {
          return (
            <li>
              <NavLink
                to={product.linkTo}
                style={({ isActive }) => ({ textDecoration: 'none', color: isActive ? colors.BLUE : colors.RED })}
              >
                <Typography style={{ fontSize: 20 }}>{product.name}</Typography>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default RangeOfProducts;
