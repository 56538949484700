import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Layout from 'components/layout/Layout';
import { colors } from 'helpers/colors';

function WhyUs() {
  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Grid container textAlign='left'>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography variant='h4' color={colors.RED}>
              Reliable delivery & logistics
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <ul>
                <li>Customer focus, flexibility and speed are our maxims.</li>
                <li>Reliable & Faster Delivery periods with respect to locations</li>
                <li>Logistical solutions tailored to customer requirements:</li>
                <li>Kanban system, just-in-time delivery</li>
                <li>Experienced Team of Professionals dedicated to deliver big projects consistently without compromise in quality</li>
              </ul>
            </Typography>

            <Typography variant='h4' color={colors.RED}>
              Future Plans
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <ul>
                <li>Setting up 20000 Sq. Ft New Factory at Nearby Industrial Estate, Jamnagar in coming 5 Years.</li>
                <li>
                  Exports of Machined Components & New Product ranges made from Specialized Metals along with - Brass, MS, SS & Aluminum
                  Category with forward Vertical & Horizontal expansion.
                </li>
                <li>Converting Systech Metals from Traditional to Modern Selling capabilities & One-Roof Set Up for customer support.</li>
              </ul>
            </Typography>

            <Typography variant='h4' color={colors.RED}>
              Corporate Social Responsibility
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              With regard to Health & Safety, we aim to achieve the following Objectives:
              <ul>
                <li>To Recognize & comply with the requirements of all relevant Legal, Statutory & other Provisions</li>
                <li>Provide a safe working environment, safe work equipment and safe methods of work to all our employees</li>
                <li>To Provide appropriate financial and physical resources necessary to achieve these aims</li>
                <li>
                  To Provide sufficient information, instruction, training and supervision to minimize & manage hazards and to contribute
                  positively to Safety at work
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} textAlign='center'>
            <img alt='' src='/assets/images/why.jpg' />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default WhyUs;
