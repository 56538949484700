import { Container, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Layout from 'components/layout/Layout';
import { colors } from 'helpers/colors';

const COPPER_ALLOY = [
  {
    title: 'Brass',
    description:
      'Free Cutting Brass, CZ121, CZ131, CDA345, C3600, CW614N, HPB59-1,  CW601N, CW602N, CW603N, CW608N, CW610N, CW612N, CW614N, CW617N, CW624N',
  },
  { title: 'Lead-free brass', description: 'Cuphin, Ecobrass' },
  { title: 'Copper', description: 'CW102C, CW118C, E-CU' },
  { title: 'Bronze', description: 'CW453K, CW456K, Phosphorus Bronze (CuZn7, CuZn5, C5441)' },
];

const STEEL_ALLOY = [
  {
    title: 'Stainless Steel',
    description: '1.1140, 1.1141, 1.4021, 1.4104, 1.4105, 1.4113, 1.4114, 1.4122, 1.4301, 1.4305, 1.4306, 1.4401, 1.4404, 1.4418',
  },
  { title: 'Free machining steel', description: '1.0037, 1.0308, 1.0503, 1.0504, 1.0715, 1.0718, 1.0736, 1.0737, 1.3505' },
  {
    title: 'Steel',
    description:
      'C45,12L14, 9SMnPB28K, 11SMnPb13, EN24, EN42, EN30B, AISI8620, 100CR, MS4.8, MS 8.8, EN8, EN9, EN19, EN36, SAE8620, EN353, EN47',
  },
];

const ALUMINUM = [{ title: 'Alloys', description: '6061-T6, 6082-T6, AW2014, AW2011' }];

function AboutUs() {
  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Typography variant='h4' color={colors.RED} textAlign='left'>
          About Systech Metals
        </Typography>

        <Typography variant='h5' color={colors.BLUE} textAlign='left'>
          Glimpse of our company
        </Typography>

        <br />
        <br />

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Year of establishment
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              2022
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Promoter
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              Mrs. Shital Parag Sumaria
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Group company
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              S P products
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Type of activities
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              Trading & Exports
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Ownership
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              Proprietary firm
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Area of office
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              600 Sq. Ft
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Specialization
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              Customized Components made from Brass/Aluminum/Copper/Steel
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Work history
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              1955 - Established manufacturing of nylon buttons
              <br />
              1998 - Started sales of Brass products
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Other associations
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              Vendor Sourcing Activities for Extrusion Materials
              <br />
              Specialized Components Annual Contract
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h5' color={colors.RED} textAlign='left'>
              Export area
            </Typography>

            <Typography variant='h5' color={colors.BLUE} textAlign='left'>
              European Countries
              <br />
              USA
              <br />
              Australia
              <br />
              Asian Countries
              <br />
              Gulf Countries
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div style={{ backgroundColor: '#f9f9f9', paddingTop: 20, paddingBottom: 20 }}>
        <Container>
          <Typography variant='h4' color={colors.RED} textAlign='left'>
            Company Policy
          </Typography>

          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
                We concentrate to the individual and work with one another with all respect and in partnership. Our priorities measure prime
                quality standards and environmental protection, wonderful client satisfaction, the continual improvement of all processes
                and procedures and promoting cooperation and coaching.
              </Typography>

              <Typography variant='h4' color={colors.RED} textAlign='left'>
                Customer satisfaction
              </Typography>

              <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
                We aim to satisfy our customers by offering the very best in price, quality and delivery reliability.
              </Typography>

              <Typography variant='h4' color={colors.RED} textAlign='left'>
                Suppliers
              </Typography>

              <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
                When selecting our suppliers, we give precedence to materials and services sourced locally to our sites, assuming technical
                and commercial comparability. We expect our suppliers and service providers to set similar standards for quality and
                environmental protection
              </Typography>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <img src='/assets/images/about-us/about.jpg' alt='' width='100%' style={{ margin: 20 }}></img>
            </Grid> */}
          </Grid>
        </Container>
      </div>

      <br />
      <br />

      <Container>
        <Typography variant='h4' color={colors.RED} textAlign='left'>
          Material We Support
        </Typography>

        <Typography variant='h5' color={colors.BLUE} textAlign='left'>
          Copper alloy
        </Typography>

        <Table>
          <TableBody>
            {COPPER_ALLOY.map((productRangeData, index) => {
              return (
                <TableRow>
                  <TableCell style={{ backgroundColor: colors.RED, border: '1px solid #ddd' }} width='200px'>
                    <Typography color='white'>{productRangeData.title}:</Typography>
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <Typography variant='body1'>{productRangeData.description}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <br />
        <Typography variant='h5' color={colors.BLUE} textAlign='left'>
          Steel alloy
        </Typography>

        <Table>
          <TableBody>
            {STEEL_ALLOY.map((productRangeData, index) => {
              return (
                <TableRow>
                  <TableCell style={{ backgroundColor: colors.RED, border: '1px solid #ddd' }} width='200px'>
                    <Typography color='white'>{productRangeData.title}:</Typography>
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <Typography variant='body1'>{productRangeData.description}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <br />

        <Typography variant='h5' color={colors.BLUE} textAlign='left'>
          Aluminum
        </Typography>

        <Table>
          <TableBody>
            {ALUMINUM.map((productRangeData, index) => {
              return (
                <TableRow>
                  <TableCell style={{ backgroundColor: colors.RED, border: '1px solid #ddd' }} width='200px'>
                    <Typography color='white'>{productRangeData.title}:</Typography>
                  </TableCell>
                  <TableCell style={{ border: '1px solid #ddd' }}>
                    <Typography variant='body1'>{productRangeData.description}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Container>
    </Layout>
  );
}

export default AboutUs;
