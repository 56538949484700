import { Button, Container, Divider, Grid, Table, TableBody, Typography } from '@mui/material';
import Layout from 'components/layout/Layout';
import { colors } from 'helpers/colors';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { NavLink } from 'react-router-dom';

const Carousel = require('react-responsive-carousel').Carousel;
const PRODUCT_RANGE_DATA = [
  {
    title: 'Electrical',
    description:
      'Branch Terminals, Terminal Bars, Connectors, Sockets, Terminal clamps Boxes, Special Terminals, Connecting Terminals, Terminal strips in a wide range of sizes',
  },
  {
    title: 'Pneumatic & Hydraulic',
    description:
      'Complex Precision Parts, LPG & CNG Fitting Parts, Hydraulic Fitting Parts, Components for Laser & Plasma Cutting, Compression Fitting Parts, Pneumatic Fittings Parts',
  },
  {
    title: 'Automotive',
    description:
      'Union Nuts in High & Low Pressure fuel Pipes, Components for Speedometer, Components for Ampere Meter, Components for Voltmeter, Components for Carburetors',
  },
  { title: 'Aerospace & Medical Technology', description: 'Fittings, Bolts , Bracket Adaptors, Flanges, Screws, Spacers, Bushings' },
  {
    title: 'Telecommunication & Network',
    description: 'Coaxial Connectors, Components for Optical Data Transmission, Relay Sockets, PCB Terminals, Audio / Video Connectors',
  },
  { title: 'Energy', description: `Conductor connection clamps, Copper Lugs, Distributor blocks, Earthing Equipment's, Earthing Rods` },
];

function Home() {
  return (
    <Layout>
      <Carousel
        autoPlay={true}
        dynamicHeight={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        swipeable={true}
        emulateTouch={true}
        stopOnHover={false}
      >
        <div>
          <img alt='' src='/assets/images/banner-1.jpg' />
        </div>
        <div>
          <img alt='' src='/assets/images/banner-2.jpg' />
        </div>
        <div>
          <img alt='' src='/assets/images/banner-3.jpg' />
        </div>
        <div>
          <img alt='' src='/assets/images/banner-4.jpg' />
        </div>
      </Carousel>

      <br />
      <br />

      <Container>
        <Grid container textAlign='left'>
          <Grid item xs={12} md={12}>
            <Typography variant='h4' color={colors.RED}>
              Welcome to Customized Metals World
            </Typography>

            <Typography variant='h5' color={colors.BLUE}>
              Our reliability speaks for itself
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Systech Metals sells exactness components from copper base alloys and varied forms of steel, atomic number 13 & Bronze
              components. It is equipped with technological power to cater customers demand.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Our product portfolio options, among others, exactness components for fuel lines, sensors, management units and attachment
              nozzles further as innovative in-house developed components for power distribution. These parts meet all national and
              international standards approved by recognized bodies.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              We believe honest, 100% transparency and are committed to supply quality elements as per client's specification.
            </Typography>

            <Button variant='contained' style={{ backgroundColor: colors.RED }}>
              <NavLink to='/about-us' style={{ textDecoration: 'none', color: 'white' }}>
                Read More
              </NavLink>
            </Button>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <Container>
              <Grid container>
                <Grid item xs={4}>
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-1.jpg' />
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-2.jpg' />
                </Grid>

                <Grid item xs={4}>
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-3.jpg' />
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-4.jpg' />
                </Grid>

                <Grid item xs={4}>
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-5.jpg' />
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-6.jpg' />
                  <img className='massonry-image' alt='' src='/assets/images/welcompart-img-7.jpg' />
                </Grid>
              </Grid>
            </Container>
          </Grid> */}
        </Grid>

        <br />

        <Divider />

        <br />
      </Container>

      <Container>
        <Grid container textAlign='left'>
          <Typography variant='h4' color={colors.RED} style={{ marginBottom: 20 }}>
            Industry Specific Product Range
          </Typography>

          <Table>
            <TableBody>
              {PRODUCT_RANGE_DATA.map((productRangeData, index) => {
                return (
                  <p>
                    <Typography variant='h5' color={colors.BLUE}>
                      {productRangeData.title} Products
                    </Typography>

                    <Typography variant='body1'>{productRangeData.description}</Typography>
                  </p>
                );
              })}
            </TableBody>
          </Table>
        </Grid>

        <br />

        <Divider />

        <br />
      </Container>

      <Container>
        <Grid container textAlign='left'>
          <Grid item xs={12}>
            <Typography variant='h4' color={colors.RED}>
              Vision & Mission
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Striving to be the recognized performance leader in commerce & Delivery of bespoke Metal parts.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Be the most effective within the eyes of our customers, workers and shareholders by setting the very best standards in our
              product, dependableness, safety and sweetening of client productivity with innovative solutions.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default Home;
