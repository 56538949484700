import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

function ComingSoon() {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} textAlign='center' justifyContent='center'>
          <Typography variant='h2'>Systech Metals</Typography>
          <Typography variant='subtitle1'>Coming Soon</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default ComingSoon;
