import { Typography } from '@mui/material';
import { colors } from 'helpers/colors';
import { NavLink } from 'react-router-dom';

function RangeOfVendorProducts() {
  return (
    <div style={{ border: `2px solid ${colors.RED}` }}>
      <Typography variant='h5' color='white' style={{ paddingLeft: 10, backgroundColor: colors.RED }}>
        Vendor Products
      </Typography>

      <ul>
        {[
          { name: 'CNC Turned Parts', linkTo: '/vendor-products/cnc-turned-parts' },
          { name: 'Brass & S.S. Hardware Parts', linkTo: '/vendor-products/brass-hardware-parts' },
          { name: 'Handrail Balustrades & Door Knobs', linkTo: '/vendor-products/handrail-door-knobs' },
          { name: 'Brass/M.S./S.S. Fasteners', linkTo: '/vendor-products/bass-fasteners' },
          { name: 'Plastic & Rubber Parts', linkTo: '/vendor-products/plastic-rubber-parts' },
          { name: 'Extrusion Rods', linkTo: '/vendor-products/extrusion-rods' },
        ].map((product, index) => {
          return (
            <li>
              <NavLink
                to={product.linkTo}
                style={({ isActive }) => ({ textDecoration: 'none', color: isActive ? colors.BLUE : colors.RED })}
              >
                <Typography style={{ fontSize: 20 }}>{product.name}</Typography>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default RangeOfVendorProducts;
