import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Layout from 'components/layout/Layout';
import { colors } from 'helpers/colors';

function Quality() {
  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Grid container textAlign='left'>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography variant='h4' color={colors.RED}>
              Quality Standards
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Our products and services are thoroughly developed and integrated throughout the entire supply chain into capable processes to
              achieve Kaizen, Poke-Yoke & Efficient PPM Level Performance. Additional Documentation: PPAP Level III
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Our solo motive is the satisfaction of our valuable Customers & our only Motto is to meet customers’ requirements effectively
              & efficiently for our Customers, Company & Society.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              We are committed to pursue Excellency through Continuous Improvement in all areas of business processes and to distinguish
              ourselves by the quality, delivery & cost efficiency of our products & services, with the total Involvement of our employees.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              We are committed to adopt & Implement Latest International Standards, Techniques & Systems for ensuring Total Quality Control.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Diameter can be maintain within 5 microns, Capable to work at 50 ppm level.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              Quality check process is periodically reviewed and required steps are taken in order to meet the ever changing customer
              expectations and the quality standard set for the products.
            </Typography>

            <Typography variant='h4' color={colors.RED}>
              Quality Assurance
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <strong>MACHINES</strong> - Conventional & Semi - Conventional Machines
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <strong>INSPECTION</strong> - Goal is to have Zero Defect products achieved through Inspection during Raw Material Source
              Stage, On Job Inspection, Final Inspection and Visual Inspection Stage.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <strong>INSTRUMENTS</strong> - Company has invested judiciously to have majority type of Inspection instruments including all
              general purpose in instruments and gages along with the profile projector and other sensitive instruments.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <strong>CALIBRATING</strong> - Each and every Instrument and Gauges are periodically calibrated, to ensure integrity of
              measurement preventive.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <strong>MAINTENANCE</strong> - Company has documented procedure to conduct the preventive maintenance of each and every
              machinery and instruments.
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <strong>TRAINING</strong> - In house as well as training from professional bodies are organized.
            </Typography>

            <Typography variant='h4' color={colors.RED}>
              Quality Instruments & Gauges
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <ul>
                <li>All Types of Ring Gauges</li>
                <li>Micro Meter</li>
                <li>All Types of Plug Gauges</li>
                <li>Various Type of Dial Gauges</li>
                <li>Hole Deepness Gauges</li>
                <li>Vernier Calipers</li>
                <li>Slip Gauges</li>
              </ul>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} textAlign='center'>
            <img alt='' src='/assets/images/quality.png' />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default Quality;
