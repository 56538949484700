import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Layout from 'components/layout/Layout';
import RangeOfVendorProducts from 'components/RangeOfVendorProducts';
import { colors } from 'helpers/colors';

function VendorProducts() {
  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Grid container textAlign='left'>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography variant='h4' color={colors.RED}>
              Processes we can manage
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <ul>
                <li>Automatic Turning</li>
                <li>Hard Turning</li>
                <li>Full Turning</li>
                <li>Forging</li>
                <li>Sheet Cutting</li>
                <li>Sand Casting</li>
                <li>Broaching</li>
                <li>Annealing</li>
                <li>Anodizing</li>
                <li>Phosphating Zinc Plating</li>
              </ul>
            </Typography>

            <Typography variant='h4' color={colors.RED}>
              Processing Range
            </Typography>

            <Typography variant='body1' textAlign='justify' style={{ marginTop: 20, marginBottom: 20 }}>
              <ul>
                <li>Diameter 1mm to 100mm</li>
                <li>Across flat 2.5mm to 85mm</li>
                <li>Square 2.5mm to 60mm</li>
                <li>Diameter can be maintained within 5 microns.</li>
              </ul>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} textAlign='center'>
            <img alt='' src='/assets/images/machineries/partners-img-1.jpg' />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <RangeOfVendorProducts />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default VendorProducts;
