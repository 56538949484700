import { yupResolver } from '@hookform/resolvers/yup';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Layout from 'components/layout/Layout';
import { colors } from 'helpers/colors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'react-simple-snackbar';
import * as Yup from 'yup';

type ContactFormInput = {
  name: string;
  email: string;
  message: string;
};

const defaultFormValues = { name: '', email: '', message: '' };

function ContactUs() {
  const [openSnackbar] = useSnackbar({
    position: 'bottom-right',
    style: {
      backgroundColor: 'white',
      border: '2px solid black',
      color: 'black',
    },
    closeStyle: {
      color: 'black',
    },
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  const onSubmit: SubmitHandler<ContactFormInput> = async (values) => {
    console.log('Form submitted.');
    console.log(values);

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    let raw = JSON.stringify({
      'name': values.name,
      'email': values.email,
      'message': values.message,
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    await fetch('https://03o66pr4q9.execute-api.ap-south-1.amazonaws.com/default/systechmetals-contact', requestOptions);

    openSnackbar('Form submitted.');

    reset();
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ContactFormInput>({ resolver: yupResolver(validationSchema), defaultValues: defaultFormValues });

  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Grid container textAlign='left'>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign='center'>
            <br />
            <br />

            <Typography variant='h4' color={colors.RED}>
              Contact details
            </Typography>

            <br />
            <br />

            <LocationOnIcon style={{ width: 120 }} />

            <Typography variant='body1'>
              Shop No: B-16, Hirji Mistry Ancillary Bhavan,
              <br />
              Opp. Shed No: B-46, Udhyog Nagar,
              <br />
              Jamnagar - 361004, Gujarat (INDIA)
            </Typography>

            <br />
            <br />

            <PhoneIcon style={{ width: 120 }} />

            <Typography variant='body1'>
              <a href='tel:+919377752444' style={{ textDecoration: 'none', color: 'black' }}>
                +91 93777 52444
              </a>
            </Typography>

            <br />
            <br />

            <MailOutlineIcon style={{ width: 120 }} />

            <Typography variant='body1'>
              <a href='mailto:metals@systechmetals.com' style={{ textDecoration: 'none', color: 'black' }}>
                metals@systechmetals.com
              </a>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign='center'>
            <br />
            <br />

            <Typography variant='h4' color={colors.RED}>
              Contact form
            </Typography>

            <br />
            <br />

            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!errors.name}
                  type='text'
                  helperText={errors.name?.message}
                  id='name'
                  label='Name'
                  variant='outlined'
                  style={{ width: 400 }}
                  {...field}
                />
              )}
            />

            <br />
            <br />

            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!errors.email}
                  type='text'
                  helperText={errors.email?.message}
                  id='email'
                  label='Email'
                  variant='outlined'
                  style={{ width: 400 }}
                  {...field}
                />
              )}
            />

            <br />
            <br />

            <Controller
              name='message'
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!errors.message}
                  type='text'
                  helperText={errors.message?.message}
                  id='message'
                  label='Message'
                  variant='outlined'
                  style={{ width: 400 }}
                  multiline
                  minRows={5}
                  maxRows={5}
                  {...field}
                />
              )}
            />

            <br />
            <br />

            <Button variant='contained' style={{ backgroundColor: colors.RED }} onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default ContactUs;
