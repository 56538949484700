import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Layout from 'components/layout/Layout';
import RangeOfProducts from 'components/RangeOfProducts';
import { colors } from 'helpers/colors';
import { useNavigate, useParams } from 'react-router-dom';

function TerminalBars() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FEATURES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Any kind of Neutral Link or Terminal can be made as per customer's specifications.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        TYPES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        2 Way, 4 Way, 6 Way, 8 Way, 10 Way, 12 Way, 14 Way, 16 Way, 20 Way, 22 Way, 24 Way, 26 Way, 28 Way, 30 Way, 32 Way, 34 Way, 36 Way.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        LENGTH SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Any size as per custom design or requirement. Also Available in meter Length.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Begins from M2 or equivalent threads. Also available in ISO Metric (MM Threads) / BA / BSW (inches) / UNC / UNF or Any as per
        Customer Design.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Free Cutting Brass IS 319 Type (I) or BS 249 Type (I) or High Grade & other As per Customers Requirement & Drawing.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural/ Nickel Plated/ Tin Plated or as per Customers Requirement or Drawing.
      </Typography>
    </>
  );
}

function BrassFittings() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FEATURES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Bushes, Flanged Plugs, Back nuts, Male extensions, Radiator Keys, Air vents and Caps, Olives, Couplers, Manifolds and Saddles
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Brass Hose Nipples and Brass hose tails Hose Barbs for Rubber, PVC, Reinforced and Synthetic Hoses. Machined to perfection and
        available in different forms with or without threads as per requirements.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Any size as per custom design or requirement. Also Available in meter Length.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        BSP (Gas), BSPT, NPT NPTF and Metric.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural Brass, Chrome or Nickel. Also available Brass Nuts for Hose / Line Connectors.
      </Typography>
    </>
  );
}

function BrassInserts() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>ISO metric (MM Threads)</li>
          <li>BA Threads</li>
          <li>BSW Threads</li>
          <li>UNC & UNF Threads</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        PLATING
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural, Nickle Plated, Tin Plated or any coating as per customer specification.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>Free Cutting Brass IS 319 Type (I) or</li>
          <li>Free Cutting Brass as Per BS 249 Type (I) or</li>
          <li>High Grade Free Cutting Brass</li>
          <li>
            Any Special Brass Material Composition as Per Customers Requirement. Inserts made from MS, SS, Copper Material also available as
            per drawings &Specifications
          </li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Available as per Customer Requirements & Specifications
      </Typography>
    </>
  );
}

function BrassTerminals() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        TYPE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Round, Square, Flat, Beda, L shape. As per Customer’s Drawing and Specifications.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        LENGTH / SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Begins from M2 or equivalent threads and up to any size as per custom design or requirement
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        ISO Metric (MM Threads) / BA / BSW (inches) / UNC / UNF or Any as per Customer Design.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Free Cutting Brass IS 319 Type (I) or BS 249 Type (I) or High Grade & other As per Customers Requirement & Drawing.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural/ Nickel Plated/ Tin Plated Special brass turned parts or any as per Customers Requirement or Drawing.
      </Typography>
    </>
  );
}

function BrassConnector() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>ISO metric (MM Threads)</li>
          <li>BA Threads</li>
          <li>BSW Threads</li>
          <li>UNC & UNF Threads</li>
          <li>As Per Customers Requirements</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>Free Cutting Brass IS 319 Type (I) or</li>
          <li>Free Cutting Brass as Per BS 249 Type (I) or</li>
          <li>High Grade Free Cutting Brass.</li>
          <li>Any Special Brass Material Composition as Per Customers Requirement.</li>
          <li>Connectors in SS & MS Materials are also provided.</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        PLATING
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural, Nickel Plated, Tin Plated or Any Coating As Per Customers Specifications.
      </Typography>
    </>
  );
}

function PrecisionParts() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FEATURES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Any kind of special turned parts can be made as per customer's specifications.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        LENGTH / SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Begins from M2 or equivalent threads and up to any size as per custom design or requirement
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        ISO Metric (MM Threads) / BA / BSW (inches) / UNC / UNF or Any as per Customer Design.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Free Cutting Brass IS 319 Type (I) or BS 249 Type (I) or High Grade & other As per Customers Requirement & Drawing.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural/ Nickel Plated/ Tin Plated Special brass turned parts or any as per Customers Requirement or Drawing
      </Typography>
    </>
  );
}

function SheetMetalComponents() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FEATURES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>MS MCB Switch Parts</li>
          <li>MCB Switch Parts</li>
          <li>MCB Switch Components</li>
          <li>MCB Electrical Components</li>
          <li>Brass Washers</li>
          <li>Brass Stamping Components</li>
          <li>Brass Square Flange</li>
          <li>Brass Sheet Punching Parts</li>
          <li>Plain washer to BS 4320: 1968 DIN 125, 433 and 9021.</li>
          <li>
            Also available special pressed part pressings and deep drawn component,pressing and parts for Switchgear, Transformer, Elevator,
            Heater and other electromechanical application.
          </li>
          <li>Pressing up to 5mm thickness can be provided on 400 tone press.</li>
        </ul>
      </Typography>
    </>
  );
}

function AluminumTurnedParts() {
  return (
    <>
      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Aluminum parts for Pipe Fittings, Electrical, Automotive and Fastening use are offered by us.
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        General Aluminum Turned parts as per customer's specifications are also available.
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        We specialize in aluminum electrical connectors, Bushes Nuts Manifolds, Aluminum Conduit Fittings, Hex Punch Grub Screws etc.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        2 mm to 100 mm in diameter
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural or Anodized as per Customer Specifications
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        NOTE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Aluminum die-cast Components are also available.
      </Typography>
    </>
  );
}

function AssemblyComponents() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        High Conductivity Copper Alloy / Bronze
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        GLAND SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        10 mm² to 500 mm²
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        COPPER TERMINALS MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        High conductivity seamless copper tube to BS 2874 -C 101ASTM B 152-C11000.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        COPPER TERMINALS FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Uniform electro-Tin plating for operating temperatures up to 150 °C. For operating temperatures above 150 °C & up to 350 °C
        Electrolytic Nickel plating is suggested
      </Typography>
    </>
  );
}

function SSTurnedParts() {
  return (
    <>
      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        We produce a wide range of Mild Steel & Stainless Steel Parts, Stainless Steel Turned Parts for high corrosive applications.
        Stainless Steel 304 / 316 Stop Plugs
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>Stainless Steel Ferrules</li>
          <li>Stainless Steel Bushes</li>
          <li>Stainless Steel Nipples</li>
          <li>Stainless Steel Unions</li>
          <li>Stainless Steel Welding Nipples</li>
          <li>Stainless Steel Cap Screws</li>
          <li>Stainless Steel Bulkhead</li>
          <li>Stainless Steel Manifolds</li>
          <li>Stainless Steel Hydraulic Fittings</li>
          <li>Stainless Steel Parts for Pressure Gauges and Meters.</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        From 2 mm to 200 mm in diameter
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIALS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        S. S. to AISI 303, 304, 304L, 316 & 316L.
      </Typography>
    </>
  );
}

function CableGlands() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        E1W CABLE GLAND
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        10 mm to 50 mm
        <br />
        This all-weather gland effects IP66 seals between the cable outer sheath and the gland nut and between the cable inner sheath and
        the gland body.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        A1-A2 CABLE GLAND
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        16 mm to 110 mm S & L<br />
        Manufactured in brass to comply with the specifications and requirement of BS 6121. Suitable for use on rubber or plastic, unarmored
        cable providing an IP66 dust and moisture seal on the outer sheath of cable
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        BW2 CABLE GLAND
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        20 mm to 90 mm S & L<br />
        Use with SWA, plastic and rubber (Elastomer) sheathed cables. Used in dry in-door application for armored cable.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        BW4 CABLE GLAND
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        20 mm to 90 mm S & L<br />
        Suitable for SWA, plastic and rubber sheathed cables. Used in indoor applications.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MARINE CABLE GLAND
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        10 mm to 70 mm
        <br />
        Used for various marine applications
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        BW4 CABLE GLAND
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        PG 7 to PG 48
        <br />
        To DIN 46320/46255 available with strain relief facility with round or hex intermediate sockets and long and short anti threads.
        Used indoor conditions inner ring frames positive armor clamp an earth continuity. Material is brass and nickel plated.
      </Typography>
    </>
  );
}

function IndustrialNuts() {
  return (
    <>
      <Typography variant='h5' color={colors.BLUE} textAlign='left'>
        BRASS NUTS
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>IS 319 Brass Free Cutting</li>
          <li>As per BS-249 Free cutting Brass Material</li>
          <li>High Grade Free Cutting</li>
          <li>Any Special Brass Material Composition on Request</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        STANDARD
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        To DIN 934, BS 450, BS 1083 & BS 3692 (Full and Lock / Half Nuts)
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Metric Hex Nuts, Heavy Hex Nuts, Full Brass Hex Nuts, Brass Metric Half Nuts, Brass Hex Lock Nuts From 2 mm to 50 mm in thread size
        machined nuts bar turned fasteners nuts
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        M3 M4 M5 M6 M8 M10 m12 M16 M20 3mm 2mm 3.5mm 4mm 5mm 6mm 8mm 10mm 12mm 16mm 20mm 1/8 5/32 3/16 1/4 5/16 3/8 1/2 5/8 3/4 1 BSP, BSW,
        UNEF, UNF, UNC, Metric BA, threaded screws fasteners fittings nuts
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Brass Nuts are Brass natural, Electro-Tinned, chrome lated Nickel etc.
      </Typography>

      <Typography variant='h5' color={colors.BLUE} textAlign='left'>
        STEEL LOCK NUTS
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Steel Lock Nuts, Conduit Lock Nuts, Brass Lock Nuts, are from 20 mm, 25mm,32mm, 40mm, 63mm, 75mm, 50 mm, & ½, 3/8, ½, ¼, 5/16, 5/8,
        1, 11/4, 2" Metric nuts
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Steel Lock Nuts, Conduit Lock Nuts, Brass Lock Nuts available in Mild Steel , Zinc & Aluminium
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Steel Lock Nuts, Conduit Lock Nuts, Brass Lock Nuts are plain or Electro Galvanized
      </Typography>
    </>
  );
}

const PRODUCT_DETAILS = [
  { title: 'Terminal Bars', route: 'terminal-bars', images: [''], details: TerminalBars },
  { title: 'Brass Fittings', route: 'brass-fittings', images: ['/assets/images/products/bfitting.jpg'], details: BrassFittings },
  { title: 'Brass Inserts', route: 'brass-inserts', images: ['/assets/images/products/binserts.jpg'], details: BrassInserts },
  {
    title: 'Brass Terminals',
    route: 'brass-terminals',
    images: ['/assets/images/products/brassterminal1.jpg', '/assets/images/products/brassterminal2.jpg'],
    details: BrassTerminals,
  },
  { title: 'Brass Connectors', route: 'brass-connectors', images: ['/assets/images/products/brassconnector.jpg'], details: BrassConnector },
  {
    title: 'Precision Eng. Parts',
    route: 'precision-eng-parts',
    images: ['/assets/images/products/precision.jpg'],
    details: PrecisionParts,
  },
  {
    title: 'Sheet Metal Components',
    route: 'sheet-metal-components',
    images: ['/assets/images/products/sheetmetalco.jpg'],
    details: SheetMetalComponents,
  },
  {
    title: 'Aluminum Turned Parts',
    route: 'aluminum-turned-parts',
    images: ['/assets/images/products/aluminium1.jpg', '/assets/images/products/aluminium2.jpg'],
    details: AluminumTurnedParts,
  },
  {
    title: 'Copper/Bronze/Ceramic Assembly Components',
    route: 'assembly-components',
    images: ['/assets/images/products/assembly2.jpg'],
    details: AssemblyComponents,
  },
  { title: 'M.S. & S.S. Turned Parts', route: 'ms-ss-turned-parts', images: ['/assets/images/products/msss.jpg'], details: SSTurnedParts },
  { title: 'Cable Glands', route: 'cable-glands', images: ['/assets/images/products/cgland.jpg'], details: CableGlands },
  { title: 'Industrial Nuts', route: 'industrial-nuts', images: ['/assets/images/products/bnuts.jpg'], details: IndustrialNuts },
];

function ProductDetails() {
  let params = useParams();
  const navigate = useNavigate();

  const productDetails = PRODUCT_DETAILS.filter((a) => a.route === params.productId)[0];

  if (!productDetails) {
    navigate('/');
  }

  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Grid container textAlign='left' spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <RangeOfProducts />
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Typography textAlign='left' variant='h4' color={colors.RED}>
              {productDetails.title}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                {<productDetails.details />}
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                {productDetails.images.map((image, index) => {
                  return <img alt='' src={image} width='100%' />;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default ProductDetails;
