import { Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Layout from 'components/layout/Layout';
import RangeOfVendorProducts from 'components/RangeOfVendorProducts';
import { colors } from 'helpers/colors';
import { useNavigate, useParams } from 'react-router-dom';

function TurnedParts() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FEATURES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Any kind of special turned parts can be made as per customer's specifications.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        LENGTH / SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Begins from M2 or equivalent threads and up to any size as per custom design or requirement
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        ISO Metric (MM Threads) / BA / BSW (inches) / UNC / UNF or Any as per Customer Design.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Free Cutting Brass IS 319 Type (I) or BS 249 Type (I) or High Grade & other As per Customers Requirement & Drawing.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Natural/ Nickel Plated/ Tin Plated Special brass turned parts or any as per Customers Requirement or Drawing.
      </Typography>
    </>
  );
}

function BrassHardwareParts() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        TYPES
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Tower Bolts / Butt Hinges / Baby Latch / Fittings / Mirror Brackets / Window Fittings / Mirror caps / Aldraps
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>ISO metric (MM Threads)</li>
          <li>BA Threads</li>
          <li>BSW Threads</li>
          <li>UNC & UNF Threads</li>
          <li>As Per Customers Requirements</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>IS 319 Brass Free Cutting</li>
          <li>As per BS-249 Free cutting Brass Material</li>
          <li>SS 202 & 304</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        FINISH
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>S.S. Finish</li>
          <li>Lacour S.L.</li>
          <li>Brass Antique</li>
          <li>Chrome</li>
        </ul>
      </Typography>
    </>
  );
}

function HandrailDoorKnobs() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        HANDRAIL & BALUSTRADES SYSTEM
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>Round Balustrade</li>
          <li>Square Balustrade</li>
          <li>Flat Balustrade</li>
          <li>Wall Mounted Handrail</li>
        </ul>
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        HARDWARE PRODUCTS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        <ul>
          <li>Lever Handle</li>
          <li>Lever Handle With Plate</li>
          <li>Mortise Lock</li>
          <li>Pin Cylinder</li>
          <li>Door Accessories</li>
        </ul>
      </Typography>
    </>
  );
}

function BrassFasteners() {
  return (
    <>
      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        MATERIAL
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        IS319, BS2874, Phosphor Bronze, Copper, Mild Steel as per BS6105: 1981, BSEN 20898-1 1992
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        THREADS
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        ISO Metric, BSW, BA, BSF, UNC, UNF etc.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        DRIVE STYLE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Slotted, Phillips (Type-I), Hex Socket, Pozi, Slotted and Phillips(combination)
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        HEAD STYLE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        Hex, Pan and Cheese Head screws, Round & Oval, Cross-Recessed, Pan, Flat, Truss Head Screws etc.
      </Typography>

      <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
        SIZE
      </Typography>

      <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
        From 1mm up to 50mm or as per customer’s specification
      </Typography>
    </>
  );
}

const PRODUCT_DETAILS = [
  { title: 'CNC Turned Parts', route: 'cnc-turned-parts', images: ['/assets/images/products/cncturned.jpg'], details: TurnedParts },
  {
    title: 'Brass & S.S. Hardware Parts',
    route: 'brass-hardware-parts',
    images: ['/assets/images/products/hardware1.jpg', '/assets/images/products/hardware2.jpg'],
    details: BrassHardwareParts,
  },
  {
    title: 'Handrail Balustrades & Door Knobs',
    route: 'handrail-door-knobs',
    images: ['/assets/images/products/handrail1.jpg', '/assets/images/products/handrail2.jpg'],
    details: HandrailDoorKnobs,
  },
  {
    title: 'Brass/M.S./S.S. Fasteners',
    route: 'bass-fasteners',
    images: ['/assets/images/products/fastener1.jpg', '/assets/images/products/fastener2.jpg'],
    details: BrassFasteners,
  },
];

function VendorProductDetails() {
  let params = useParams();
  const navigate = useNavigate();

  if (params.productId === 'plastic-rubber-parts') {
    return (
      <>
        <Layout>
          <img alt='' src='/assets/images/background.jpg' width='100%' />

          <br />
          <br />

          <Container>
            <Grid container textAlign='left' spacing={2}>
              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Typography textAlign='left' variant='h4' color={colors.RED}>
                  Plastic & Rubber Parts
                </Typography>

                <Typography variant='h5' color={colors.BLUE} textAlign='left'>
                  BRANCHING TERMINAL BASE
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <img alt='' src='/assets/images/products/branching.jpg' width='100%' />
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      MATERIAL
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Housing Base – ABS, Cover – polycarbonate
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      CONNECTOR BLOCK
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Brass Grade CZ-121 or any Grade as per customer Requirements
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      CONNECTOR PLATING
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Zinc Plated, Nickel, Tin Plated or as per customer requirements
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      CONNECTOR
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Multiple Incoming and Outgoing, as per Customer’s Drawing and Specifications.
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      SCREWS
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      M.S. Zinc Plated
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      CURRENT RATING
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      150 Amps to 400 Amps or as per Customer’s Drawings & Specifications. Size & Length as per Customer Specifications &
                      Drawing.
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant='h5' color={colors.BLUE} textAlign='left'>
                  NEUTRAL LINK BASE & CONNECTOR BASE
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <img alt='' src='/assets/images/products/neutral.jpg' width='100%' />
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      MATERIAL
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      PP FR/ ABS/ DMC/ BACKALITE/ POLYAMIDE
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      TYPE
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Terminal Base with 14mm to 19mm, Din Rail Mounted Terminal base, C – Channel Base, Single Way Earth Bar, 3 Way Earth
                      Bar, Equipotential Earth Bonding Bar, Din Rail Mounted Connectors, Mains power Connector, Length & Size according to
                      customer Drawing & Specifications.
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      APPLICATIONS
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Terminal Bases Suitable for Fixing of Neutral Links & Terminal Connectors.
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant='h5' color={colors.BLUE} textAlign='left'>
                  TERMINAL BASES SUITABLE FOR FIXING TERMINAL CONNECTORS
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <img alt='' src='/assets/images/products/tbase.jpg' width='100%' />
                  </Grid>

                  <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      MATERIAL
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      PPV2 for Housing
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      TERMINAL
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Brass With or without Plating
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      SCREWS
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      MS Zinc Plated
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      RATING
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      6A, 10A,15A, 30A & 60A
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      LENGTH & SIZE
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      Available in 2, 3, 4, 5, 6, 8, 10 & 12 no of ways
                    </Typography>

                    <Typography textAlign='left' variant='subtitle1' color={colors.RED}>
                      FEATURES
                    </Typography>

                    <Typography variant='body1' textAlign='justify' style={{ marginBottom: 20 }}>
                      <ul>
                        <li>Sturdy construction</li>
                        <li>High efficiency</li>
                        <li>Flexible Design</li>
                        <li>Fine finish</li>
                        <li>Easy to install</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <RangeOfVendorProducts />
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </>
    );
  } else if (params.productId === 'extrusion-rods') {
    return (
      <>
        <Layout>
          <img alt='' src='/assets/images/background.jpg' width='100%' />

          <br />
          <br />

          <Container>
            <Grid container textAlign='left' spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography textAlign='left' variant='h4' color={colors.RED}>
                  Extrusion Rods
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS ROUND RODS
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE : 1.5 TO 50MM
                    <br />
                    LENGTH : 6 TO 8 FEET
                    <br />
                    MOQ : 200 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS HEX RODS
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE: 4 TO 50MM
                    <br />
                    LENGTH: 6 TO 8 FEET
                    <br />
                    MOQ: 200 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS SQAURE RODS
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE: 4 TO 50MM
                    <br />
                    LENGTH: 6 TO 8 FEET
                    <br />
                    MOQ: 200 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS FLAT RODS
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE: 6.35 TO 50MM
                    <br />
                    LENGTH: 6 TO 8 FEET
                    <br />
                    MOQ: 200 KGS
                    <br />
                    Grade as per Customer Requirements
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS HOLLOW RODS
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE: 20 TO 50MM & ID – 14MM MIN.
                    <br />
                    LENGTH: 6 TO 8 FEET
                    <br />
                    MOQ: 200 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS & COPPER PIPES
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE: 6 TO 50MM
                    <br />
                    LENGTH: 6 TO 8 FEET
                    <br />
                    MOQ: 200 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS FORGING RODS
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    LENGTH: 6 TO 8 FEET
                    <br />
                    MOQ: 500 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS COIL WIRE
                  </Typography>

                  <Typography variant='body1' color='white' textAlign='left'>
                    SIZE: 1.5 TO 6MM IN FREE CUTTING.
                    <br />
                    SIZE: 1.5 TO 10MM IN LEAD FREE.
                    <br />
                    MOQ: 500 KGS
                    <br />
                    Grade as per Customer Requirements.
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <div className='about-box' style={{ minHeight: '150px' }}>
                  <Typography variant='h5' color='white' textAlign='left'>
                    BRASS SECTIONAL RODS AS PER CUSTOMER DRAWINGS & REQUIREMENTS
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </>
    );
  }

  const productDetails = PRODUCT_DETAILS.filter((a) => a.route === params.productId)[0];

  if (!productDetails) {
    navigate('/');
  }

  return (
    <Layout>
      <img alt='' src='/assets/images/background.jpg' width='100%' />

      <br />
      <br />

      <Container>
        <Grid container textAlign='left' spacing={2}>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Typography textAlign='left' variant='h4' color={colors.RED}>
              {productDetails.title}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                {productDetails.images.map((image, index) => {
                  return <img alt='' src={image} width='100%' />;
                })}
              </Grid>

              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                {<productDetails.details />}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <RangeOfVendorProducts />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default VendorProductDetails;
