import { Grid } from '@mui/material';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import { ReactNode } from 'react';

function Layout({ children }: { children: ReactNode }) {
  return (
    <div>
      <Header />

      <Grid container>
        <Grid item xs={12} textAlign='center' justifyContent='center'>
          {children}
        </Grid>
      </Grid>

      <br />
      <br />

      <Footer />
    </div>
  );
}

export default Layout;
