import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AboutUs from 'components/AboutUs';
import ContactUs from 'components/ContactUs';
import Home from 'components/Home';
import ComingSoon from 'components/layout/ComingSoon';
import ProductDetails from 'components/ProductDetails';
import Products from 'components/Products';
import Quality from 'components/Quality';
import ScrollToTop from 'components/ScrollToTop';
import VendorProductDetails from 'components/VendorProductDetails';
import VendorProducts from 'components/VendorProducts';
import WhyUs from 'components/WhyUs';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SnackbarProvider from 'react-simple-snackbar';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about-us' element={<AboutUs />} />
              <Route path='/why-us' element={<WhyUs />} />
              <Route path='/products' element={<Products />} />
              <Route path='/products/:productId' element={<ProductDetails />} />
              <Route path='/vendor-products' element={<VendorProducts />} />
              <Route path='/vendor-products/:productId' element={<VendorProductDetails />} />
              <Route path='/quality' element={<Quality />} />
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='*' element={<ComingSoon />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>,
);
