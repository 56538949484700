import { Container, Paper, Typography } from '@mui/material';
import { colors } from 'helpers/colors';
import { NavLink } from 'react-router-dom';

const MENU_ITEMS = [
  { name: 'Home', linkTo: '/' },
  { name: 'About Us', linkTo: '/about-us' },
  { name: 'Why Us', linkTo: '/why-us' },
  {
    name: 'Products',
    linkTo: '/products/brass-fittings',
    subMenu: [
      // { name: 'Terminal Bars', linkTo: '/products/terminal-bars' },
      { name: 'Brass Fittings', linkTo: '/products/brass-fittings' },
      { name: 'Brass Inserts', linkTo: '/products/brass-inserts' },
      { name: 'Brass Terminals', linkTo: '/products/brass-terminals' },
      { name: 'Brass Connectors', linkTo: '/products/brass-connectors' },
      { name: 'Precision Eng. Parts', linkTo: '/products/precision-eng-parts' },
      { name: 'Sheet Metal Components', linkTo: '/products/sheet-metal-components' },
      { name: 'Aluminum Turned Parts', linkTo: '/products/aluminum-turned-parts' },
      { name: 'Copper/Bronze/Ceramic Assembly Components', linkTo: '/products/assembly-components' },
      { name: 'M.S. & S.S. Turned Parts', linkTo: '/products/ms-ss-turned-parts' },
      { name: 'Cable Glands', linkTo: '/products/cable-glands' },
      { name: 'Industrial Nuts', linkTo: '/products/industrial-nuts' },
    ],
  },
  // {
  //   name: 'Vendor Products',
  //   linkTo: '/vendor-products/cnc-turned-parts',
  //   subMenu: [
  //     { name: 'CNC Turned Parts', linkTo: '/vendor-products/cnc-turned-parts' },
  //     { name: 'Brass & S.S. Hardware Parts', linkTo: '/vendor-products/brass-hardware-parts' },
  //     { name: 'Handrail Balustrades & Door Knobs', linkTo: '/vendor-products/handrail-door-knobs' },
  //     { name: 'Brass/M.S./S.S. Fasteners', linkTo: '/vendor-products/bass-fasteners' },
  //     { name: 'Plastic & Rubber Parts', linkTo: '/vendor-products/plastic-rubber-parts' },
  //     { name: 'Extrusion Rods', linkTo: '/vendor-products/extrusion-rods' },
  //   ],
  // },
  { name: 'Quality', linkTo: '/quality' },
  { name: 'Contact Us', linkTo: '/contact-us' },
];

function Header() {
  return (
    <Container>
      <Paper elevation={0}>
        <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '2rem' }}>
          <NavLink to='/'>
            <img alt='' src='/logo.png' height={70} />
          </NavLink>

          <ul style={{ listStyle: 'none', padding: 0, margin: 0, display: 'flex', gap: '2rem' }}>
            {MENU_ITEMS.map((menuItem, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={menuItem.linkTo}
                    style={({ isActive }) => ({ textDecoration: 'none', color: isActive ? colors.BLUE : colors.RED })}
                  >
                    <Typography style={{ fontSize: 20, textAlign: 'center' }}>{menuItem.name}</Typography>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </Paper>
    </Container>
  );
}

export default Header;
